import Footer from "@modules/layout/templates/footer"
import Nav from "@modules/layout/templates/nav"
import React, { ReactNode } from "react"

type Props = {
  children?: ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <Nav />
      <main className="relative">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
