import { Product } from "@medusajs/medusa"
import SilverMedal from "@modules/common/icons/silver-medal"
import { ProductPreviewType } from "types/global"

export const getAttributes = (
  tags: Product["tags"]
): ProductPreviewType["attributes"] => {
  const isAOP = !!tags.find((t) => t.value.toUpperCase() === "AOP")
  const isBio = !!tags.find((t) => t.value.toUpperCase() === "BIO")
  const medalTag = tags.find((t) => t.value.startsWith("MEDAL_"))

  let medal
  if (!!medalTag) {
    medal = SilverMedal
  }

  return {
    isAOP,
    isBio,
    medal,
  }
}
