import NextHead from "next/head"
import React from "react"

type HeadProps = {
  title?: string
  description?: string | null
  image?: string | null
}

const Head: React.FC<HeadProps> = ({ title, description, image }) => {
  return (
    <NextHead>
      <title>{`${title} | Bizzz de Corse`}</title>
      <meta itemProp="name" content={title} />
      {description && <meta name="description" content={description} />}
      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  )
}

export default Head
