import clsx from "clsx"
import Link from "next/link"
import { ProductPreviewType } from "types/global"
import Thumbnail from "../thumbnail"
import Bio from "@modules/common/icons/bio"
import Aop from "@modules/common/icons/aop"

const ProductPreview = ({
  title,
  handle,
  thumbnail,
  price,
  attributes,
}: ProductPreviewType) => {
  const Medal = attributes.medal

  return (
    <Link href={`/products/${handle}`}>
      <div>
        <Thumbnail thumbnail={thumbnail} size="full" Badge={Medal} />
        <div className="text-base-regular mt-2">
          <span>{title}</span>
          <div className="mt-1 flex justify-between items-center">
            <div className="flex items-center gap-x-2">
              {price ? (
                <>
                  {price.price_type === "sale" && (
                    <span className="line-through text-gray-500">
                      {price.original_price}
                    </span>
                  )}
                  <span
                    className={clsx("font-semibold", {
                      "text-rose-500": price.price_type === "sale",
                    })}
                  >
                    {price.calculated_price}
                  </span>
                </>
              ) : (
                <div className="w-20 h-6 animate-pulse bg-gray-100"></div>
              )}
            </div>
            <div className="flex space-x-2">
              {attributes.isBio && <Bio />}
              {attributes.isAOP && <Aop />}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ProductPreview
