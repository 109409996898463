import clsx from "clsx"
import Link from "next/link"
import CountrySelect from "../country-select"
import { useProductCategories } from "medusa-react"
import { useMemo } from "react"

const FooterNav = () => {
  const { product_categories } = useProductCategories({})

  const productCategories = useMemo(() => {
    return product_categories?.filter((c) => !c.parent_category_id)
  }, [product_categories])

  return (
    <div className="content-container flex flex-col gap-y-8 pt-16 pb-8">
      <div className="flex flex-col gap-y-6 xsmall:flex-row items-start justify-center">
        <div className="text-small-regular grid grid-cols-2 gap-x-16 md:gap-x-64">
          <div className="flex flex-col gap-y-2">
            <span className="text-base-semi">Nos produits</span>
            <ul
              className={clsx("grid grid-cols-1 gap-y-2", {
                "grid-cols-2": (productCategories?.length || 0) > 4,
              })}
            >
              {productCategories?.map((c) => (
                <li key={c.id}>
                  <Link href={`/categories/${c.handle}`}>{c.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col gap-y-2">
            <span className="text-base-semi">Bizzz de Corse</span>
            <ul className="grid grid-cols-1 gap-y-2">
              <li>
                <Link href="/content/notre-histoire">Notre histoire</Link>
              </li>
              <li>
                <Link href="/content/contact">Nous contacter</Link>
              </li>
              <li>
                <Link href="/content/cgv">Conditions générales</Link>
              </li>
              <li>
                <Link href="/content/mentions-legales">Mentions légales</Link>
              </li>
            </ul>
          </div>
          {/* <div className="flex flex-col gap-y-2">
            <span className="text-base-semi">Medusa</span>
            <ul className="grid grid-cols-1 gap-y-2">
              <li>
                <a
                  href="https://github.com/medusajs"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>
              </li>
              <li>
                <a
                  href="https://docs.medusajs.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/medusajs/nextjs-starter-medusa"
                  target="_blank"
                  rel="noreferrer"
                >
                  Source code
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col-reverse gap-y-4 justify-center xsmall:items-center xsmall:flex-row xsmall:justify-between">
        <span className="text-xsmall-regular text-gray-500">
          © Copyright {new Date().getFullYear()} Bizzz de Corse
        </span>
        <div className="min-w-[316px] flex xsmall:justify-end">
          <CountrySelect />
        </div>
      </div>
    </div>
  )
}

export default FooterNav
